let doctype_list = ['Sales Invoice', 'Sales Order', 'Purchase Invoice', 'Purchase Order', 'Payment Entry', 'Journal Entry'];

for (let doc of doctype_list){
    frappe.ui.form.on(doc, {
        refresh: function(frm){
            let today = new Date;
            let allow_edit_upto = today.setDate(today.getDate() - 2);
            let allow_edit = frappe.user_roles.includes("System Manager") || (Date.parse(frm.doc.creation) > allow_edit_upto &&
                frm.doc.owner == frappe.user.name);
            if (frm.doc.docstatus != 1 || !allow_edit || frm.page.current_view_name == 'print') return;
            frm.page.set_primary_action('Edit', function(){                 
                let d = new frappe.ui.Dialog({
                    title: 'Reason for Edit',
                    fields: [
                        {
                            label: 'Reason',
                            fieldname: 'reason',
                            fieldtype: 'Small Text',
                            reqd: 1,
                        }
                    ],
                    primary_action_label: 'Edit',
                    primary_action(value) {
                        d.hide();
                        frm.validate_form_action('Cancel');	
                        frappe.validated = true;
                        frm.script_manager.trigger("before_cancel").then(function() {

                            var after_cancel = async function(r) {
                                frm.refresh();
                                await frm.script_manager.trigger("after_cancel");
                                frappe.call({
                                    method: 'smc_erp.monkey_patches.cancel_and_edit.update_status_for_edit',
                                    args: {
                                        doctype: frm.doctype,
                                        docname: frm.docname,
                                        user: frappe.user.name,
                                        comment: value.reason,
                                    },
                                    callback: function(){
                                        frm.reload_doc();
                                        if(frm.doc.items) {
                                            frm.add_child('items');
                                        }
                                        else if(frm.doc.accounts){
                                            frm.add_child('accounts');
                                        }
                                        frm.save();
                                    }
                                });
                            };
                            frappe.ui.form.save(frm, "cancel", after_cancel);
                        });
                    }
                });
                
                d.show();

            }, 'octicon octicon-pencil')
        },
    });
}