var docs_array = ["Quotation", "Sales Order", "Delivery Note","Sales Invoice",
                    "Purchase Order", "Purchase Receipt", "Purchase Invoice"]

for (var i = 0; i < docs_array.length; i++) {
    frappe.ui.form.on(docs_array[i], "setup", function(frm) {

        $(frm.wrapper).on('grid-row-render', function(e, row) {

    	// Check if Row is Pipe and has UOM Factor of Kg
    	if (row.doc.item_code && row.doc.docstatus != 1) {
    	    frappe.call({
                method: "frappe.client.get_value",
                args: {
    	   	     		'doctype' : "UOM Conversion Detail",
    	   	    		'filters' : {
        	   	               'parent' : row.doc.item_code,
    			    	       'uom' : "Kg"
        		         },
						'fieldname' : ["conversion_factor"],
						'parent': "Item"  
                },
    	  		callback: function(res) {
    				if(res.message){
    					row.doc.weight_per_kg = flt(1 / res.message.conversion_factor, 2);
    					row.doc.rate_per_kg =
                            row.doc.rate / row.doc.weight_per_kg / row.doc.conversion_factor;
    					row.refresh_field("rate_per_kg");
                        row.toggle_editable("rate_per_kg", true);
    				} else {
    					row.doc.rate_per_kg = 0;
    					row.refresh_field("rate_per_kg");
    					row.toggle_editable("rate_per_kg", false);
    				}
    	    	}
        	});

        } // End if statement

        }); // End grid-row-render

    }); // End frappe.ui.form.on

    frappe.ui.form.on(docs_array[i] + " Item", "rate_per_kg", function(doc, cdt, cdn) {
        var d = locals[cdt][cdn];
    	if (d.rate_per_kg){
    		frappe.model.set_value(d.doctype, d.name, "rate",
    			 d.rate_per_kg * d.weight_per_kg * d.conversion_factor);
    	}
    });  // End frappe.ui.form.on

} // End for loop
