frappe.ui.form.on("Address", {
    pincode: function(frm) {
        if(frm.doc.pincode){
            frappe.call({
                method: "smc_erp.api.get_pincode_details.get_pincode_details",
                args: {"pincode": frm.doc.pincode},
                callback: (res) => {
                    let pincode_details = res.message;
                    if(!pincode_details[0])return;

                    frm.doc.city = pincode_details[0];
                    frm.doc.state = pincode_details[1];
                    frm.refresh_fields();
                }
            })
        }
    }
});