getOpenLedgerLinks();

window.onhashchange =  function(){
   setTimeout(() => {
    getOpenLedgerLinks();
   }, 7000);
};

function getOpenLedgerLinks () {
    let OpenLedgerFor = {
        'Accounts Receivable': 'Customer',
        'Accounts Receivable Summary': 'Customer',
        'Accounts Payable': 'Supplier',
        'Accounts Payable Summary': 'Supplier'
    };

    for (let i in OpenLedgerFor){
        if (!document.URL.endsWith(`query-report/` + encodeURI(i))) continue;
        waitForElementToDisplay(".dt-cell__content", function(){
            open_ledger(OpenLedgerFor[i]);     
        }, 1000);
        break;
    }
}

function open_ledger(party){
	$('.datatable').on('mouseenter', `:has([data-doctype='${party}']):first-child`, function(){
		let docname = $(this).find('.grey').attr('data-name');
		let fromDate = frappe.defaults.get_default('year_start_date');
		let filters = `{ party_type: '${party}', party: '${docname}', from_date: '${fromDate}' }`;
		let params = `'query-report', 'General Ledger', ${filters}`;
		$( this ).append(`<span style='font-size: xx-small; font-style: italic;'>&nbsp;<a href='#' onclick="frappe.set_route(${params}); return false;">Open Ledger</a></span>`);
	});
	$('.datatable').on('mouseleave', `:has([data-doctype='${party}']):first-child`, function(){
		$( this ).find( "span" ).last().remove();
	});
}

function waitForElementToDisplay(selector, callback, checkFrequencyInMs) {
    (function loopSearch() {
      if (document.querySelector(selector) != null) {
        callback();
        return;
      }
      else {
        setTimeout(function () {
          loopSearch();
        }, checkFrequencyInMs);
      }
    })();
  }
