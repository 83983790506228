import '../../templates/item_details/smc_item_details.html';
import "./utils";
import "./custom_theme";
import "./common";
import "./pdf_button";
import "./rate_per_kg";
import "./bulk_print";
import "./cleaner";
import "./open_ledger";
import "./quick_reports";
import "./pincode_api";
import "./cancel_and_edit";
import './sales_history';
