
	frappe.templates['smc_item_details'] = `<style type="text/css">
    .font-size-14 {
        font-size: 14px;
    }
</style>

<div class="item-details my-4">
    Item Details for
    <span class="font-size-14 font-weight-bold">
        {{ frappe.utils.get_form_link("Item", item_details.item_code, true) }}
    </span>

    {% if (!item_details.sales_history.length) { %}
    <div class="mt-4">
        <span class="font-size-14">No sales history found for this item.</span>
    </div>
    {% } %}

    <div class="row mt-4">

        {% if (item_details.sales_history.length) { %}
        <div class="col-lg-8">
            <span class="item-detail-heading">Sales History</span>
            <table class="table table-bordered mt-2">
                <thead>
                    <tr>
                        <th>Invoice Date</th>
                        <th>Order No.</th>
                        <th>Invoice No.</th>
                        <th class="text-right">Qty</th>
                        <th class="text-right">Rate</th>
                    </tr>
                </thead>
                <tbody>
                    {% for (const row of item_details.sales_history) { %}
                    <tr>
                        <td>{{ frappe.format(row.posting_date, {fieldtype: "Date"}) }}</td>
                        <td>{{ frappe.utils.get_form_link("Sales Order", row.sales_order, true) }}</td>
                        <td>{{ frappe.utils.get_form_link("Sales Invoice", row.name, true) }}</td>
                        <td class="text-right">{{ frappe.format(row.qty, {fieldtype: "Float"}) }}</td>
                        <td class="text-right">{{ frappe.format(row.rate, {fieldtype: "Currency", options: "currency"},
                            null, row) }}</td>
                    </tr>
                    {% } %}
                </tbody>
            </table>
        </div>
        {% } %}

    </div>
</div>`;
