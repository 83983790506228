import BulkOperations from "./../../../../frappe/frappe/public/js/frappe/list/bulk_operations";
frappe.provide('frappe.views');

class ListViewCustom extends frappe.views.ListView{
    get_actions_menu_items(){
        const get_actions_menu_items_custom = super.get_actions_menu_items ();
        const bulk_operations_custom = new BulkOperations({ doctype: this.doctype });

        //modifyPrint to download individual files
        bulk_operations_custom.print = function print(docs) {
            const me = this;
            const print_settings = frappe.model.get_doc(':Print Settings', 'Print Settings');
            const allow_print_for_draft = cint(print_settings.allow_print_for_draft);
            const is_submittable = frappe.model.is_submittable(this.doctype);
            const allow_print_for_cancelled = cint(print_settings.allow_print_for_cancelled);
    
            const valid_docs = docs.filter(doc => {
                return !is_submittable || doc.docstatus === 1 ||
                    (allow_print_for_cancelled && doc.docstatus == 2) ||
                    (allow_print_for_draft && doc.docstatus == 0) ||
                    frappe.user.has_role('Administrator');
            }).map(doc => doc.name);
    
            const invalid_docs = docs.filter(doc => !valid_docs.includes(doc.name));
    
            if (invalid_docs.length > 0) {
                frappe.msgprint(__('You selected Draft or Cancelled documents'));
                return;
            }
    
            if (valid_docs.length > 0) {
                const dialog = new frappe.ui.Dialog({
                    title: __('Print Documents'),
                    fields: [{
                        'fieldtype': 'Check',
                        'label': __('With Letterhead'),
                        'fieldname': 'with_letterhead'
                    },
                    {
                        'fieldtype': 'Select',
                        'label': __('Print Format'),
                        'fieldname': 'print_sel',
                        options: frappe.meta.get_print_formats(this.doctype)
                    }]
                });
    
                dialog.set_primary_action(__('Print'), args => {
                    if (!args) return;
                    const default_print_format = frappe.get_meta(this.doctype).default_print_format;
                    const with_letterhead = args.with_letterhead ? 1 : 0;
                    const print_format = args.print_sel ? args.print_sel : default_print_format;
                    
                    for (let docname of valid_docs) {
                        const w = window.open('/api/method/frappe.utils.print_format.download_pdf?' +
                            'doctype=' + encodeURIComponent(me.doctype) +
                            '&name=' + encodeURIComponent(docname) +
                            '&format=' + encodeURIComponent(print_format) +
                            '&no_letterhead=' + (with_letterhead ? '0' : '1'));

                        if (!w) {
                            frappe.msgprint(__('Please enable pop-ups'));
                            return;
                        }
                    }
                    
                });
    
                dialog.show();
            } else {
                frappe.msgprint(__('Select atleast 1 record for printing'));
            }
        }

        //modifyAction
        for(let item of get_actions_menu_items_custom){
            if (item.label != 'Print') continue;
            item.action = () => bulk_operations_custom.print(this.get_checked_items());
            break;        
        }
        
        return get_actions_menu_items_custom;
    }
};

frappe.views.ListView = ListViewCustom;

// default for with letter head =1 for report print
let old_get_print_settings = frappe.ui.get_print_settings;
frappe.ui.get_print_settings = function() {
    let dialog = old_get_print_settings.apply(null, arguments);
    dialog.fields_dict.with_letter_head.set_value(1);
    return dialog;
}