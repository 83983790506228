// Cleaning unrequired elements from ERP

//clean custom-buttons
const unused_features = ['Pick List', 'Delivery Note', 'Work Order', 'Request for Raw Materials',
     'Project', 'Subscription', 'Payment Request', 'Maintenance Schedule', 'Lead', 'Oppourtunity',
    'Product Bundle', 'Supplier Quotation', 'Receipt', 'Purchase Receipt', 'Generate IRN', 'Invoice Discounting',
    'Fetch Timesheet'];

const doctype_with_features = ['Sales Order', 'Sales Invoice', 'Quotation', 'Purchase Order', 'Purchase Invoice']

for (let dt of doctype_with_features){
    frappe.ui.form.on(dt, {
        onload_post_render: function(frm){
            for(let feat of unused_features){
                frm.remove_custom_button(feat, "Create")
                frm.remove_custom_button(feat, "Get Items From")
                frm.remove_custom_button(feat, "E Invoicing")
            }
        },
    });
}
