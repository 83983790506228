frappe.provide('smc_erp');
smc_erp.render_pdf_report = render_pdf_report;
smc_erp.receivable_payable_report = receivable_payable_report;
smc_erp.gl_report = gl_report;
smc_erp.gl_dialog = gl_dialog;

async function render_pdf_report(report_name, filters){
    const default_letter_head = frappe.defaults.get_default('Letter Head');
    const print_settings = await frappe.db.get_doc('Print Settings');
    print_settings.with_letter_head = 1;
    print_settings.letter_head = frappe.boot.letter_heads[default_letter_head]

    const base_url = frappe.urllib.get_base_url();
    const print_css = frappe.boot.print_css;

    const result = await frappe.call({
        method: 'smc_erp.monkey_patches.report_print.custom_generate_report_result',
        args: {
            'report_name': report_name,
            'filters': filters
        }
    });
    const columns = result.message.columns;
    const data = result.message.result;
    const applied_filters = filters;

    const report_settings = await frappe.call({
        method: 'smc_erp.monkey_patches.report_print.get_script',
        args: {
            'report_name': report_name
        }
    });
    const template = report_settings.message.html_format

    const content = frappe.render_template(template, {
        title: report_name,
        filters: applied_filters,
        data: data,
        columns: columns
    });

    // Render Report in HTML
    const html = frappe.render_template('print_template', {
        title: report_name,
        content: content,
        base_url: base_url,
        print_css: print_css,
        print_settings: print_settings,
        landscape: true,
        columns: columns,
        lang: frappe.boot.lang,
        layout_direction: frappe.utils.is_rtl() ? "rtl" : "ltr",
        can_use_smaller_font: 0,
    });
    frappe.render_pdf(html, print_settings);
}

function receivable_payable_report(dt, party, tax_id){
    let report_name = (dt == "Supplier")? "Accounts Payable": "Accounts Receivable";
    let filters = {
        company: frappe.defaults.get_user_default('Company'),
        ageing_based_on: "Posting Date",
        report_date: frappe.datetime.get_today(),
        range1: 30,
        range2: 60,
        range3: 90,
        range4: 120,
        [dt.toLowerCase()]: party,
        tax_id: tax_id,
        show_remarks: 1,
    };
    return [report_name, filters];
}

function gl_report(dt, party, tax_id, from_date, to_date){
    let report_name = "General Ledger";
    let filters = {
        company: frappe.defaults.get_user_default('Company'),
        from_date: from_date,
        to_date: to_date,
        party_type: dt,
        group_by: 'Group by Voucher (Consolidated)',
        include_default_book_entries: 1,
        party: [party],
        tax_id: tax_id,
        cost_center: [],
        project: [],
        show_remarks: 1,
    };

    return [report_name, filters];
}

function gl_dialog(doctype, name, tax_id){
    let dialog = new frappe.ui.Dialog({
        title: 'Report Date',
        fields: [
            {
                label: 'From Date',
                fieldname: 'from_date',
                fieldtype: 'Date',
                default: frappe.defaults.get_default('year_start_date')
            },
            {
                fieldname: 'col_break',
                fieldtype: 'Column Break'
            },
            {
                label: 'To Date',
                fieldname: 'to_date',
                fieldtype: 'Date',
                default: 'Today'
            }
        ],
        primary_action_label: 'Download',
        primary_action(values) {
            let input = gl_report(doctype, name, tax_id, values.from_date, values.to_date)
            render_pdf_report(input[0], input[1]);
            dialog.hide();
        }
    });
    return dialog
}
