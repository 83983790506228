for (const doctype of ["Quotation", "Sales Order"]) {
    frappe.ui.form.on(doctype, {
        refresh(frm) {
            set_item_details(frm, "");
            frm._item_details = {};
            setup_item_focus_events(frm);
        },
        party_name(frm) {
            if (frm.doc.quotation_to !== "Customer") return;

            set_item_details(frm, "");
            frm._item_details = {};
        },
        customer(frm) {
            set_item_details(frm, "");
            frm._item_details = {};
        },
        after_save(frm) {
            set_item_details(frm, "");
        },
    });

    frappe.ui.form.on(`${doctype} Item`, {
        item_code(frm, cdt, cdn) {
            const row = frappe.get_doc(cdt, cdn);
            update_item_details(frm, row.item_code);
        },
        items_add(frm, cdt, cdn) {
            setup_item_focus_events(frm, cdn);
        },
    });
}

function setup_item_focus_events(frm, row_name) {
    if (!frm.doc.items || !frm.doc.items.length || frm.doc.docstatus !== 0) {
        return;
    }
    let rows = frm.fields_dict.items.grid.grid_rows;
    if (row_name) {
        rows = rows.filter((row) => row.doc.name === row_name);
    }

    for (const row of rows) {
        if (!row.columns || !row.columns.item_code) continue;

        row.columns["item_code"].on("click", () => {
            update_item_details(frm, row.doc.item_code);
        });
    }
}

async function update_item_details(frm, item_code) {
    let customer = frm.doc.customer;
    if (frm.doc.doctype == "Quotation") {
        if (frm.doc.quotation_to !== "Customer") {
            return;
        }

        customer = frm.doc.party_name;
    }

    if (!customer || !item_code || frm.doc.docstatus !== 0) return;

    if (frm._item_details[item_code]) {
        render_item_details(frm, item_code);
        return;
    }

    const { message: item_details } = await frappe.call({
        method: "smc_erp.smc_tweaks.get_item_sales_history.get_item_sales_history",
        args: {
            item_code,
            customer,
        },
    });

    frm._item_details[item_code] = item_details;
    render_item_details(frm, item_code);
}

function render_item_details(frm, item_code) {
    const item_details = frm._item_details[item_code];
    set_item_details(
        frm,
        frappe.render_template("smc_item_details", { item_details })
    );
}

function set_item_details(frm, html) {
    frm.get_field("smc_item_details").html(html);
}